import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import syncPr from './syncPr';
import errorSuccess from './errorSuccess';
import URL_UTILITY from '../../../../utility/url.utility';
export default {
  name: 'PrIntegration',
  watch: {
    currentPage: function() {
      this.getprIntegrationDtls();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getprIntegrationDtls();
    }
  },
  components: { DatePicker, syncPr, errorSuccess },
  data() {
    return {
      loader: false,
      isSuccess: false,
      // perPage: commonHelper.perPageRecord,
      perPage: 20,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      responseMsg: '',
      fileName: { text: null, value: null },
      status: { text: null, value: null },
      uploadDateFrom: null,
      uploadDateTo: null,
      requestId: null,
      showAlert: false,
      showValueSetModal: false,
      prIntegrationData: [],
      prIntegrationFields: [
        // {
        //   key: 'selectBox',
        //   label: 'Select',
        //   stickyColumn: true,
        //   variant: 'info',
        //   class: 'stickyColumn col-sm-1 col-fix'
        // },
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'le_name',
          label: 'Legal Entity',
          class: 'col-fix'
        },
        {
          key: 'file_name',
          class: 'col-fix col-width-fix'
        },
        {
          key: 'ext_file_name',
          label: 'Ext. File Name',
          class: 'col-fix'
        },
        {
          key: 'record_count',
          class: 'col-fix'
        },
        {
          key: 'request_status',
          label: 'Request Status',
          class: 'col-fix'
        },
        {
          key: 'record_uploaded',
          label: 'Success Count',
          class: 'col-fix'
        },
        {
          key: 'error_count',
          class: 'col-fix'
        },
        {
          key: 'request_id',
          class: 'col-fix'
        },
        {
          key: 'status_meaning',
          label: 'Status',
          class: 'col-fix'
        },
        {
          key: 'created_by',
          class: 'col-fix'
        },
        {
          key: 'creation_date',
          class: 'col-fix'
        },
        {
          key: 'upload_date',
          class: 'col-fix'
        },
        {
          key: 'pr_sync_req_id',
          label: 'Pr Sync Id',
          class: 'col-fix'
        },
        {
          key: 'pr_sync_req_status',
          label: 'Pr Sync Status',
          class: 'col-fix'
        }
      ],
      showSyncPrModal: false,
      showErrorSuccessModal: false,
      rowDtls: null,
      dataType: null,
      dowloadReport: URL_UTILITY.getDownloadReportUrl,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: true,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      },
      accessBtnSync: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: false,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      selectAllCheckBox: false,
      selectedRow: [],
      syncType: null,
      syncReqId: null,
      fileId: null,
      syncStatus: null,
      specIndex: null,
      deleteBtnFlag: false
    };
  },
  mounted() {},
  methods: {
    searchSyncPrIntegraion(legalEntity, req_id) {
      this.legalEntity = legalEntity;
      this.requestId = req_id;
      this.getprIntegrationDtls();
    },
    getprIntegrationDtls() {
      this.syncStatus = null;
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        file_id: this.fileName.value,
        upload_date_from: this.uploadDateFrom,
        upload_date_to: this.uploadDateTo,
        request_id: this.requestId,
        status: this.status.value
      };
      this.loader = true;
      this.$store
        .dispatch('complainceDashboard/getprIntegrationDtls', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.prIntegrationData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
            const syncPrStatus = this.prIntegrationData[this.specIndex]
              .pr_sync_req_status;
            if (syncPrStatus === 'COMPLETED' || syncPrStatus === 'ERROR') {
              this.syncStatus = this.prIntegrationData[this.specIndex].status;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    deletePrFile() {
      const payload = {
        file_id: this.fileId
      };
      this.loader = true;
      this.$store
        .dispatch('complainceDashboard/deletePrFilePrInt', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.$bvToast.toast(resp.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
          } else {
            this.$bvToast.toast(resp.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // syncPrInvoices() {
    //   const filterData = this.prIntegrationData.filter(data => data.selectBox);
    //   if (filterData.length) {
    //     const itemDetails = filterData.map(ele => {
    //       return {
    //         request_id: ele.request_id
    //       };
    //     });
    //     const reqPayloadPara = {
    //       req_id_dtls: itemDetails
    //     };
    //     const jsonStringReqPara = JSON.stringify(reqPayloadPara);
    //     const escapedJsonStrReqPara = jsonStringReqPara.replace(/"/g, '\\"');
    //     const reqParaPayload = `"${escapedJsonStrReqPara}"`;
    //     this.saveSubmitRequest(reqParaPayload);
    //   }
    // },
    // saveSubmitRequest(reqParaPayload) {
    //   const payload = {
    //     admin_submit_req_details: [
    //       {
    //         request_num: 0,
    //         request_id: '249', //this.requestId,
    //         template_id: '78', //this.templateId,
    //         schedule_type: 'asap', //pass hardcode
    //         request_start_date: null,
    //         request_end_date: null,
    //         resubmit_interval: null,
    //         resubmit_interval_unit: null,
    //         day_of_month: null,
    //         day_of_week: null,
    //         output_format_id: 'SBLANK', //pass hardcode
    //         no_of_args: 25,
    //         request_parameter: reqParaPayload,
    //         sms_flag: false,
    //         email_flag: false,
    //         whatsapp_flag: false,
    //         child_req_count: null,
    //         email_comm_template_id: null,
    //         sms_comm_template_id: null,
    //         whatsapp_comm_template_id: null,
    //         argument1: reqParaPayload,
    //         argument2: null,
    //         argument3: null,
    //         argument4: null,
    //         argument5: null,
    //         argument6: null,
    //         argument7: null,
    //         argument8: null,
    //         argument9: null,
    //         argument10: null,
    //         argument11: null,
    //         argument12: null,
    //         argument13: null,
    //         argument14: null,
    //         argument15: null,
    //         argument16: null,
    //         argument17: null,
    //         argument18: null,
    //         argument19: null,
    //         argument20: null,
    //         argument21: null,
    //         argument22: null,
    //         argument23: null,
    //         argument24: null,
    //         argument25: null
    //       }
    //     ]
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('template/saveSubmitRequest', payload)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 201) {
    //         // this.requestNumId = resp.data.data[0].id;
    //         // this.getSubmitRequest();
    //         this.$bvToast.toast(resp.data.message, {
    //           title: 'Alert',
    //           variant: 'success',
    //           solid: true
    //         });
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    selectedPORow(item, index) {
      this.fileId = item.file_id;
      this.syncReqId = item.request_id;
      if (
        item.pr_sync_req_status &&
        (item.pr_sync_req_status === 'COMPLETED' ||
          item.pr_sync_req_status === 'ERROR')
      ) {
        this.syncStatus = item.status;
      } else if (!item.pr_sync_req_status) {
        this.syncStatus = item.status;
      }
      this.deleteBtnFlag = item.status === 'Uploaded' ? true : false;
      this.specIndex = index;
    },
    syncPrInvoices(btnType) {
      if (this.syncReqId) {
        const payload = {
          request_id: this.syncReqId,
          request_for: btnType === 'syncRecoStatus' ? 'DOWNLOADRECO' : ''
        };
        this.loader = true;
        this.$store
          .dispatch('complainceDashboard/syncPrInvoices', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.syncStatus = null;
              this.$bvToast.toast(resp.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
            } else {
              this.$bvToast.toast(resp.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    showHideSyncPrModal(flag, type) {
      this.syncType = type;
      this.showSyncPrModal = flag;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.PR_EXTERNAL_FILE_NAME) {
        this.fileName = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.PR_EXTENSION) {
        this.status = {
          text: item.value_meaning,
          value: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.status.value) {
        this.status = {
          text: null,
          value: null
        };
      }
      if (vsetCode === this.fileName.value) {
        this.fileName = {
          text: null,
          value: null
        };
      }
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.uploadDateFrom);
    },
    clearFilters() {
      this.fileName = { text: null, value: null };
      this.status = { text: null, value: null };
      this.uploadDateTo = null;
      this.uploadDateFrom = null;
      this.requestId = null;
      this.prIntegrationData = [];
      this.totalRows = null;
      this.getprIntegrationDtls();
    },
    rowSelected() {},
    showHideErrorSuccessModal(flag, item, type) {
      this.showErrorSuccessModal = flag;
      this.rowDtls = item;
      this.dataType = type;
    },
    // selectAllBoxChecked(flag) {
    //   this.selectedRow = [];
    //   if (flag) {
    //     this.prIntegrationData = this.prIntegrationData.map((data, index) => {
    //       data.selectBox = this.selectAllCheckBox;
    //       if (this.selectAllCheckBox) {
    //         this.selectBoxChecked(data.selectBox, index);
    //       }
    //       return data;
    //     });
    //     this.selectedRow = this.prIntegrationData;
    //   } else {
    //     this.prIntegrationData = this.prIntegrationData.map((data, index) => {
    //       data.selectBox = this.selectAllCheckBox;
    //       if (this.selectAllCheckBox) {
    //         this.selectBoxChecked(data.selectBox, index);
    //       }
    //       return data;
    //     });
    //   }
    // },
    // selectBoxChecked(flag, index, item, reqId) {
    //   this.prIntegrationData[index].selectBox = flag;
    //   if (flag) {
    //     this.selectedRow.push(item);
    //   } else {
    //     this.selectedRow = this.selectedRow.filter(
    //       el => el.request_id !== reqId
    //     );
    //   }
    // },
    // syncRecoStatusData() {
    //   const payload = {
    //     pr_repo_status_req: this.selectedRow
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('complainceDashboard/syncPrRecoStatus', payload)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 201) {
    //         this.showAlert = true;
    //         this.isSuccess = true;
    //         this.responseMsg = resp.data.message;
    //         setTimeout(() => {
    //           this.showAlert = false;
    //           this.isSuccess = false;
    //           this.responseMsg = '';
    //         }, 3000);
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    downloadFile() {}
  }
};
